<template>
  <div>
    <subjects-table />
  </div>
</template>

<script>
import SubjectsTable from "@/components/SubjectsTable";

export default {
  name: "Categories",
  components: { SubjectsTable },
  created() {
    this.$store.dispatch("publicInfo/getCountriesAll");
  },
};
</script>

<style lang="scss" scoped></style>
