<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Предметы</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">{{ formTitle }}</span>
                <div class="text-h5 accent--text">
                  {{ itemCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-tabs>
                      <v-tab v-for="lang in languagesList" :key="lang.iso">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="lang.icon"
                            class="ma-0 elevation-3"
                          />
                          {{ lang.name }}
                        </div>
                      </v-tab>
                      <v-tab-item
                        v-for="(lang, index) in languagesList"
                        :key="lang.iso + '_tab_subject'"
                        eager
                        class="pt-3"
                      >
                        <v-text-field
                          :ref="`editedItem.name.${lang.iso}`"
                          v-model="editedItem.name[lang.iso]"
                          label="Название"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :error="errors.name ? true : false"
                          :error-messages="errors.name"
                          @focus="errors.name = null"
                          @blur="
                            index === 0
                              ? slugPaste(editedItem.name[lang.iso])
                              : ''
                          "
                        ></v-text-field>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="editedItem.slug"
                      v-model="editedItem.slug"
                      :rules="[rules.required]"
                      :error="errors.slug ? true : false"
                      :error-messages="errors.slug"
                      label="Slug"
                      @input="slugValue(editedItem.slug)"
                      @focus="
                        errors.slug = null;
                        slugPaste(editedItem.name.ru);
                      "
                      @blur="slugPaste(editedItem.name.ru)"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-file-input
                      v-model="fileName"
                      label="Иконка"
                      :rules="rulesIcon"
                      :error="errors.icon ? true : false"
                      :error-messages="errors.icon"
                      accept="image/png, image/jpeg, svg"
                      outlined
                      dense
                      prepend-icon="mdi-image-plus"
                      @change="icon"
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="iconSrc" cols="12" class="pt-0 pb-5">
                    <v-row>
                      <v-col cols="7"><v-img :src="iconSrc" /></v-col>
                      <v-col cols="5">
                        <v-btn fab small color="accent" @click="clearIcon">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editedItem.sort"
                      label="Сортировка"
                      type="number"
                      outlined
                      dense
                      :error="errors.sort ? true : false"
                      :error-messages="errors.sort"
                      @focus="errors.sort = null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="d-inline-block">
                      <v-switch
                        color="primary"
                        class="mt-0"
                        v-model="editedItem.active"
                        label="Видимость"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save">
                  <template v-if="editedIndex > -1"> Изменить </template>
                  <template v-else> Создать </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                >
                  <template v-if="deleteSpecIndex < 0">
                    Удалить категорию?<br />
                    <span class="accent--text">{{ itemCurrentName }}</span>
                  </template>
                  <template v-else>
                    Удалить специализацию?<br />
                    <span class="accent--text">{{ deleteSpecName }}</span>
                  </template>
                </v-card-title>

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-dialog v-model="dialogSpec" scrollable max-width="700px">
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2"> Специализации </span>
                <div class="text-h5 accent--text">
                  {{ itemCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-tabs v-model="tabSpec">
                      <v-tab
                        v-for="lang in languagesList"
                        :key="lang.iso"
                        :href="`#${lang.iso}`"
                      >
                        <div class="d-flex align-center">
                          <country-flag
                            :country="lang.icon"
                            class="ma-0 elevation-3"
                          />
                          {{ lang.name }}
                        </div>
                      </v-tab>
                      <v-tabs-items v-model="tabSpec">
                        <v-tab-item
                          v-for="lang in languagesList"
                          :key="lang.iso + '_tab_specs'"
                          :value="lang.iso"
                          eager
                          class="pt-3"
                        >
                          <v-text-field
                            :ref="`editedSpec.name.${lang.iso}`"
                            v-model="editedSpec.name[lang.iso]"
                            label="Название"
                            outlined
                            :rules="[rules.required]"
                            dense
                            :error="errors[`name.${lang.iso}`] ? true : false"
                            :error-messages="errors[`name.${lang.iso}`]"
                            @focus="errors[`name.${lang.iso}`] = null"
                            @blur="
                              slugPaste(editedSpec.name[lang.iso], [
                                'editedSpec',
                                lang.iso,
                              ])
                            "
                          ></v-text-field>
                          <v-text-field
                            :ref="`editedSpec.slug.${lang.iso}`"
                            v-model="editedSpec.slug[lang.iso]"
                            :rules="[rules.required]"
                            :error="errors[`slug.${lang.iso}`] ? true : false"
                            :error-messages="errors[`slug.${lang.iso}`]"
                            label="Slug"
                            @input="
                              slugValue(editedSpec.slug[lang.iso], [
                                'editedSpec',
                                lang.iso,
                              ])
                            "
                            @focus="
                              errors[`slug.${lang.iso}`] = null;
                              slugPaste(editedSpec.name[lang.iso], [
                                'editedSpec',
                                lang.iso,
                              ]);
                            "
                            @blur="
                              slugPaste(editedSpec.name[lang.iso], [
                                'editedSpec',
                                lang.iso,
                              ])
                            "
                            outlined
                            dense
                          ></v-text-field>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="d-inline-block">
                      <v-checkbox
                        v-model="editedSpec.is_all_countries"
                        label="Все страны"
                        :ripple="false"
                        color="accent"
                        class="mt-0 mb-2"
                        hide-details
                      >
                      </v-checkbox>
                    </div>

                    <div class="d-inline-block ml-4">
                      <v-checkbox
                        v-model="editedSpec.visibilities[tabSpec]"
                        :label="`Показывать на ${tabSpec} языке`"
                        :ripple="false"
                        color="accent"
                        class="mt-0 mb-2"
                        hide-details
                      >
                      </v-checkbox>
                    </div>

                    <div>
                      <v-autocomplete
                        v-model="editedSpec.countries_ids"
                        :items="countriesAll"
                        :disabled="editedSpec.is_all_countries"
                        label="Для стран"
                        item-text="name"
                        item-value="id"
                        multiple
                        small-chips
                        hide-details
                        deletable-chips
                        item-color="accent"
                      />
                    </div>
                  </v-col>
                  <v-col cols="auto" class="py-0 ms-auto">
                    <template v-if="editedSpecIndex > -1">
                      <v-btn
                        color="accent"
                        class="me-2"
                        outlined
                        @click="clearEditSpec"
                      >
                        отменить
                      </v-btn>
                      <v-btn color="accent" @click="updateSpec">
                        Изменить
                      </v-btn>
                    </template>
                    <v-btn v-else color="accent" @click="createNewSpec">
                      Добавить
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="specsList.length > 0">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"><small>Название</small></th>
                            <th class="text-left"><small>Slug</small></th>
                            <th class="text-no-wrap text-center">
                              <small>Все страны</small>
                            </th>
                            <th class="text-center">
                              <small>Показывать на {{ tabSpec }} языке</small>
                            </th>
                            <th class="text-end"><small>Действия</small></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(spec, ind) in specsList"
                            :key="`spec_${ind}_spec.name`"
                          >
                            <td
                              :class="{
                                'bg-light': ind === editedSpecIndex,
                              }"
                            >
                              {{ spec.name[tabSpec] }}
                            </td>
                            <td
                              :class="{
                                'bg-light': ind === editedSpecIndex,
                              }"
                            >
                              {{ spec.slug[tabSpec] }}
                            </td>
                            <td
                              :class="{
                                'bg-light': ind === editedSpecIndex,
                              }"
                              class="text-center"
                            >
                              {{ spec.is_all_countries ? "+" : "-" }}
                            </td>
                            <td
                              :class="{
                                'bg-light': ind === editedSpecIndex,
                              }"
                              class="text-center"
                            >
                              {{ spec.visibilities[tabSpec] ? "+" : "-" }}
                            </td>
                            <td
                              class="text-end"
                              :class="{
                                'bg-light': ind === editedSpecIndex,
                              }"
                              style="width: 88px"
                            >
                              <v-icon
                                small
                                class="mr-2"
                                @click="setEditedSpec(ind)"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon small @click="confirmDeleteSpec(ind)">
                                mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4 pt-5">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="closeSpecEdit"
                  >Закрыть</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.is_popular="{ item }">
        <div class="d-inline-block">
          <v-simple-checkbox
            v-model="item.is_popular"
            :ripple="false"
            color="accent"
            hide-details
            @input="changePopular(item)"
          ></v-simple-checkbox>
        </div>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-inline-block">
          <v-switch
            v-model="item.active"
            color="primary"
            class="my-0"
            hide-details
            :ripple="false"
            @change="changeStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small class="mr-2" @click="editSpec(item)">
          Специализации
        </v-btn>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import api from "../../api";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import copyValueObjs from "@/helpers/copyValueObjs";
export default {
  name: "SubjectsTable",
  mixins: [rules, slug],
  components: { CountryFlag },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogSpec: false,
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Slug",
          value: "slug",
        },
        {
          text: "Сортировка",
          value: "sort",
          align: "center",
        },
        {
          text: "Популярный",
          value: "is_popular",
          align: "center",
          width: 130,
        },
        {
          text: "Видимость",
          value: "active",
          align: "center",
          width: 130,
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
          width: 240,
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      editedSpec: null,
      editedSpecIndex: -1,
      deleteSpecIndex: -1,
      specsList: [],
      optionsTable: {},
      total: 0,
      loading: true,
      errors: {},
      rulesIcon: [
        (value) =>
          !value || value.size < 2000000 || "Максимальный размер файла 2mb",
      ],
      iconImg: null,
      fileName: null,
      tabSpec: "ru",
    };
  },

  computed: {
    ...mapGetters({
      countriesAll: "publicInfo/countriesAll",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Новый предмет" : "Изменить предмет";
    },
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].name : "";
    },
    ...mapGetters(["languagesList"]),
    iconSrc() {
      return this.iconImg ? this.iconImg : this.editedItem.icon;
    },
    deleteSpecName() {
      const spec = this.specsList[this.deleteSpecIndex];
      return spec ? spec.name.ru : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
    this.editedSpec = { ...this.createSpec() };
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      const loadItem = await this.getItem(item.id);
      this.editedItem = copyValueObjs(this.createItem(), loadItem);
      this.dialog = true;
      this.resetErrors(this.createItem(), "editedItem");
    },
    async editSpec(item) {
      this.editedIndex = this.items.indexOf(item);
      await this.getSpecs(item.id);
      this.dialogSpec = true;
      this.resetErrors(this.editedSpec, "editedSpec");
    },
    async closeSpecEdit() {
      this.dialogSpec = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.clearEditSpec();
    },
    clearEditSpec() {
      this.editedSpecIndex = -1;
      this.editedSpec = { ...this.createSpec() };
      this.resetErrors(this.editedSpec, "editedSpec");
      this.errors = {};
    },
    async setEditedSpec(index) {
      this.editedSpecIndex = index;
      this.editedSpec = { ...this.specsList[index] };
      this.errors = {};
    },
    async getSpecs(subjectId) {
      try {
        const req = await api.specs.list(subjectId);
        this.specsList = req.data;
      } catch (e) {
        console.error(e);
      }
    },
    async createNewSpec() {
      let formHasErrors = this.validationObject(this.editedSpec, "editedSpec");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      try {
        await api.specs.create(
          this.items[this.editedIndex].id,
          this.editedSpec
        );
        this.getSpecs(this.items[this.editedIndex].id);
        this.clearEditSpec();
        this.tabSpec = "ru";
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },
    async updateSpec() {
      let formHasErrors = this.validationObject(this.editedSpec, "editedSpec");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      try {
        await api.specs.update(
          this.items[this.editedIndex].id,
          this.editedSpec.id,
          this.editedSpec
        );
        this.getSpecs(this.items[this.editedIndex].id);
        this.clearEditSpec();
        this.tabSpec = "ru";
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка обновления",
            color: "error",
            show: true,
          });
        }
      }
    },
    async removeSpec(id) {
      try {
        await api.specs.delete(id);
        this.$store.dispatch("showSnackbar", {
          msg: "Специализация удалена",
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
      await this.getSpecs(this.items[this.editedIndex].id);
    },
    confirmDeleteSpec(ind) {
      this.deleteSpecIndex = ind;
      this.dialogDelete = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      if (this.deleteSpecIndex > -1) {
        await this.removeSpec(this.specsList[this.deleteSpecIndex].id);
      } else {
        await this.removeItem(this.items[this.editedIndex].id);
        this.getItems();
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.clearIcon();
        this.errors = {};
        this.resetErrors(this.createItem(), "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        if (this.deleteSpecIndex > -1) {
          this.deleteSpecIndex = -1;
          this.clearEditSpec();
        } else {
          this.editedItem = { ...this.createItem() };
          this.editedIndex = -1;
        }
      });
    },

    async save() {
      let formHasErrors = this.validationObject(
        this.createItem(),
        "editedItem"
      );
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
      } else {
        await this.addItem();
      }
      this.getItems();
    },

    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      try {
        const req = await api.subjects.subjectsList({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.makeFormData();
      try {
        await api.subjects.createSubject(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Предмет "${this.editedItem.name.ru}" создана`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.makeFormData();
      data.append("_method", "PUT");
      try {
        await api.subjects.updateSubject(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Предмет "${this.editedItem.name.ru}" изменена`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async getItem(id) {
      try {
        const req = await api.subjects.subject(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].name;
        await api.subjects.deleteSubject(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Предмет "${nameDelete}" удалена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
        this.closeDelete();
      }
    },

    async changeStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      const status = item.active;
      const loadItem = await this.getItem(item.id);
      this.editedItem = { ...loadItem, active: status };
      await this.updateItem();
    },

    createItem() {
      const keys = ["name"];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        slug: "",
        sort: 0,
        active: true,
        icon: null,
      };
    },

    createSpec() {
      const keys = ["name", "slug", "visibilities"];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          if (key === "visibilities") obj[lang.iso] = false;
          else obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        countries_ids: [],
        is_all_countries: true,
      };
    },
    icon(files) {
      if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.iconImg = e.target.result;
        };
        reader.readAsDataURL(files);
        this.editedItem.icon = files;
      } else {
        this.iconImg = null;
        this.editedItem.icon = null;
      }
    },
    makeFormData() {
      const data = new FormData();
      for (const key in this.editedItem) {
        if (
          typeof this.editedItem[key] === "object" &&
          !(this.editedItem[key] instanceof File) &&
          !(this.editedItem[key] instanceof Date)
        ) {
          for (const lang in this.editedItem[key]) {
            data.append(`name[${lang}]`, this.editedItem[key][lang]);
          }
        } else if (typeof this.editedItem[key] === "boolean") {
          data.append(key, this.editedItem[key] ? 1 : 0);
        } else if (key === "icon") {
          if (this.editedItem[key] instanceof File) {
            data.append(key, this.editedItem[key]);
          }
        } else {
          data.append(key, this.editedItem[key]);
        }
      }
      if (!this.editedItem.icon) {
        data.append("delete_icon", 1);
      }
      return data;
    },
    clearIcon() {
      this.iconImg = null;
      this.editedItem.icon = null;
      this.fileName = null;
    },
    async changePopular(item) {
      try {
        await api.subjects.togglePopular(item.id, item.is_popular);
        this.$store.dispatch("showSnackbar", {
          msg: "Изменено",
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка",
          color: "error",
          show: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
